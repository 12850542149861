import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import "../assets/sass/landscaping.sass"



const Mulch = () => (
<div className="info-page mulch">

    <div className="info-page-intro">
        <h1>Mulch</h1>
        <p>Helping you keep your property healthy, clean, and safe.</p>
    </div>
    {/* <Image filename={} /> */}

    <div className="discription">

        <p>
            At Kevins Fencing and landscaping, we’ll do whatever is needed to get your property ready for the next season.
            We come with years of experience and a reputation of doing exceptional jobs. 
            Getting your landscape back into shape after the end of the season can be a big job, and we have the team to tackle just that.
        </p>

    </div>

</div>

)

export default Mulch